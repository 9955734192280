import {
  Box,
  useDisclosure,
  Image,
  Stack,
  HStack,
  Grid,
  GridItem,
  Text,
  IconButton,
} from "@chakra-ui/react";
import ActionModal from "./ActionModal";
import API from "./secrets";
import { FiArrowRight } from "react-icons/fi";
import { React, useState, useRef } from "react";
function App({ data, setListDataReady, isDefaultExpanded }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  var {
    _id,
    sl_no,
    category,
    id_no,
    pirticular,
    supplier,
    r1_key,
    r1_value,
    r2_key,
    r2_value,
    r3_key,
    r3_value,
    r4_key,
    r4_value,
    r5_key,
    r5_value,
    current_stock,
    dp,
    dp_extra,
    mrp,
    imageObj,
  } = data;
  const [isExpanded, setIsExpanded] = useState(isDefaultExpanded);
  const initialRef = useRef();
  const finalRef = useRef();
  const [inputs, setInputs] = useState(data);

  return (
    <Box
      p="1rem"
      mb="0.5rem"
      borderRadius={5}
      shadow="lg"
      bgColor={current_stock === "0" ? "red.200" : "whiteAlpha.900"}
      onClick={() => {
        setIsExpanded(!isExpanded);
      }}
    >
      <Grid templateColumns={"repeat(10, 1fr)"} h="auto">
        <GridItem colSpan={9}>
          <Grid
            templateColumns={[
              "repeat(3, 1fr)",
              "repeat(4, 1fr)",
              "repeat(5, 1fr)",
            ]}
            h="auto"
          >
            {" "}
            <GridItem>#{id_no}</GridItem>
            <GridItem>
              <Text fontSize={["md", "1xl", "2xl"]} color="purple">
                {category ? category.toUpperCase() : ""}
              </Text>
            </GridItem>
            <GridItem colSpan={[3, 4, 5]}>
              <Stack>
                {/* <Box>Pirticular</Box> */}
                <Text fontSize={["md", "1xl", "2xl"]}>
                  <HStack>
                    <strong>{pirticular}</strong>
                    <Text color="blue.500">{r1_value}</Text>
                    <Text>{r2_value}</Text>
                  </HStack>
                </Text>
              </Stack>
            </GridItem>
            <GridItem>
              <HStack>
                <Text color="grey" fontSize={["md", "1xl", "2xl"]}>
                  MRP:
                </Text>
                <Text color="maroon" fontSize={["md", "1xl", "2xl"]}>
                  <strong> {mrp}</strong>
                </Text>
              </HStack>
            </GridItem>
            <GridItem>
              <HStack>
                <Text color="grey" fontSize={["md", "1xl", "2xl"]}>
                  <a href={"/productDetail/?pid=" + _id}>Stock:</a>
                </Text>
                <Text color="green" fontSize={["md", "1xl", "3xl"]}>
                  <strong> {current_stock} </strong>
                </Text>
              </HStack>
            </GridItem>
            {imageObj ? (
              <Image
                onClick={() =>
                  window.open(API + "/getImageByPId/?id=" + imageObj, "_blank")
                }
                loading="easy"
                src={API + "/getImageByPId/?id=" + imageObj}
              />
            ) : (
              ""
            )}
            {isExpanded ? (
              <>
                <GridItem>
                  DP:
                  <strong>
                    {dp} {dp_extra !== "0" ? "+" + dp_extra : ""}{" "}
                  </strong>
                </GridItem>
                <GridItem>
                  <HStack>
                    <Text color="grey" fontSize={["md", "1xl", "1xl"]}>
                      Supplier:
                    </Text>
                    <Text fontSize={["md", "1xl", "1xl"]}>
                      <strong> {supplier}</strong>
                    </Text>
                  </HStack>
                </GridItem>
                {r1_value ? (
                  <GridItem>
                    <HStack>
                      <Text color="grey" fontSize={["sm", "1xl", "1xl"]}>
                        {r1_key}:
                      </Text>
                      <Text fontSize={["sm", "1xl", "1xl"]}>
                        <strong> {r1_value}</strong>
                      </Text>
                    </HStack>
                  </GridItem>
                ) : (
                  ""
                )}
                {r2_value ? (
                  <GridItem>
                    <HStack>
                      <Text color="grey" fontSize={["sm", "1xl", "1xl"]}>
                        {r2_key}:
                      </Text>
                      <Text fontSize={["sm", "1xl", "1xl"]}>
                        <strong> {r2_value}</strong>
                      </Text>
                    </HStack>
                  </GridItem>
                ) : (
                  ""
                )}
                {r3_value ? (
                  <GridItem>
                    <HStack>
                      <Text color="grey" fontSize={["sm", "1xl", "1xl"]}>
                        {r3_key}:
                      </Text>
                      <Text fontSize={["sm", "1xl", "1xl"]}>
                        <strong> {r3_value}</strong>
                      </Text>
                    </HStack>
                  </GridItem>
                ) : (
                  ""
                )}{" "}
                {r4_value ? (
                  <GridItem>
                    <HStack>
                      <Text color="grey" fontSize={["sm", "1xl", "1xl"]}>
                        {r4_key}:
                      </Text>
                      <Text fontSize={["sm", "1xl", "1xl"]}>
                        <strong> {r4_value}</strong>
                      </Text>
                    </HStack>
                  </GridItem>
                ) : (
                  ""
                )}{" "}
                {r5_value ? (
                  <GridItem>
                    <HStack>
                      <Text color="grey" fontSize={["sm", "1xl", "1xl"]}>
                        {r5_key}:
                      </Text>
                      <Text fontSize={["sm", "1xl", "1xl"]}>
                        <strong> {r5_value}</strong>
                      </Text>
                    </HStack>
                  </GridItem>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </Grid>

          <ActionModal
            cardData={inputs}
            setCardData={setInputs}
            setListDataReady={setListDataReady}
            onClose={onClose}
            isOpen={isOpen}
          />
        </GridItem>
        <GridItem colSpan={1}>
          <IconButton
            h="100%"
            w="100%"
            variant="outline"
            colorScheme="red"
            // bgColor="#FFC2B4"
            onClick={() => {
              setInputs(data);
              onOpen();
            }}
            icon={<FiArrowRight />}
          />
        </GridItem>
      </Grid>
    </Box>
  );
}
export default App;
