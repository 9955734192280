import { Box, Input, FormControl, FormLabel } from "@chakra-ui/react";
import { useState } from "react";
import InputSpinner from "react-bootstrap-input-spinner";

function App({ setCardData, cardData }) {
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setCardData((values) => ({ ...values, [name]: value }));
  };
  const [oldStock, setOldStock] = useState(cardData.current_stock);

  const old_qty = Object.assign({}, cardData);
  return (
    <Box>
      <FormControl>
        <FormLabel htmlFor="sl_no">Available Qty</FormLabel>
        <Input
          bgColor="grey"
          name="av_qty"
          value={oldStock || ""}
          readOnly="readOnly"
        />
      </FormControl>

      <FormControl>
        <FormLabel htmlFor="sold_qty">Add Quantity</FormLabel>
        <Input
          bgColor="white"
          name="sold_stock"
          type="number"
          defaultValue="0"
          onChange={(e) => {
            var val = parseInt(e.target.value) || 0;
            var old = parseInt(oldStock);
            console.log(val, old);
            var new_qt = old + val;
            setCardData((values) => ({ ...values, current_stock: new_qt }));
          }}
        />
      </FormControl>
      {/* <NumberInput defaultValue="1" min={0}>
          <NumberInputField
          
            onChange={(e) => {
              console.log("change");
              var val = parseInt(e.target.value) || 0;
              var old = parseInt(oldStock);
              console.log(val, old);
              var new_qt = old - val;
              setCardData((values) => ({ ...values, current_stock: new_qt }));
            }}
            name="current_stock"
          />
          <NumberInputStepper>
            <NumberIncrementStepper
              onClick={() => {
                console.log("aaa");
              }}
            />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput> */}
      <FormControl>
        <FormLabel htmlFor="current_stock">Updated Qty</FormLabel>
        <Input
          bgColor="gray"
          name="current_stock"
          value={cardData.current_stock || ""}
          onChange={handleChange}
          readOnly="readOnly"
        />
      </FormControl>

      <FormControl>
        <FormLabel htmlFor="comment">Comments</FormLabel>
        <Input
          bgColor="white"
          name="comment"
          value={cardData.comment || ""}
          onChange={handleChange}
        />
      </FormControl>
    </Box>
  );
}
export default App;
