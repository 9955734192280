import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  useToast,
  RadioGroup,
  Stack,
  Radio,
  FormHelperText,
  Spinner,
} from "@chakra-ui/react";
import SellForm from "./Modal_SellForm";
import AddForm from "./Modal_AddForm";
import ModifyForm from "./Modal_ModifyForm";
import InputSpinner from "react-bootstrap-input-spinner";

import axios from "axios";
import { useEffect, useState } from "react";
import API from "./secrets";
function App({ cardData, setCardData, setListDataReady, onClose, isOpen }) {
  const toast = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setCardData((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    axios
      .post(API + "/modify", cardData)
      .then(function (response) {
        toast({
          title: "Saved",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setListDataReady(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Box>
      <Modal size="6xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <FormControl as="action">
              <FormLabel as="action">Do what?</FormLabel>
              <FormHelperText>Select option.</FormHelperText>
            </FormControl>
            <RadioGroup defaultValue="sell">
              <Stack spacing={5} direction="row">
                <Radio
                  colorScheme="red"
                  name="action"
                  value="sell"
                  onChange={handleChange}
                >
                  Sell
                </Radio>
                <Radio
                  onChange={handleChange}
                  colorScheme="blue"
                  name="action"
                  value="add"
                >
                  Add Stock
                </Radio>
                <Radio
                  onChange={handleChange}
                  colorScheme="green"
                  name="action"
                  value="modify"
                >
                  Modify
                </Radio>
              </Stack>
            </RadioGroup>
          </ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit}>
            <ModalBody pb={6} bgColor="#FFC2B4">
              {cardData.action === "sell" || !cardData.action ? (
                <SellForm setCardData={setCardData} cardData={cardData} />
              ) : cardData.action === "modify" ? (
                <ModifyForm setCardData={setCardData} cardData={cardData} />
              ) : (
                <AddForm setCardData={setCardData} cardData={cardData} />
              )}
            </ModalBody>

            <ModalFooter>
              {isSubmitting ? <Spinner /> : ""}
              <Button
                disabled={isSubmitting}
                type="submit"
                colorScheme="green"
                m="1rem"
              >
                Save
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  );
}
export default App;
