import { Box, Text, HStack, SimpleGrid } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import TransactionItem from "./TransactionItem";
import CategoryButton from "./CatButton";
import API from "./secrets";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function App() {
  const [state, setState] = useState([]);
  return (
    <Box bgColor="pink.100">
      <SimpleGrid p="1rem">
        <HStack>
          <label>MRP 1</label>
          <input id="n1" type="number" />
          <label>Qty 1</label>
          <input id="q1" type="number" />
          <Text id="t1"></Text>
          <Text color="red" id="w1"></Text>
        </HStack>
        <HStack>
          <label>MRP 2</label>
          <input id="n2" type="number" />
          <label>Qty 2</label>
          <input id="q2" type="number" />
          <Text id="t2"></Text>
          <Text color="red" id="w2"></Text>
        </HStack>{" "}
        <HStack>
          <label>MRP 3</label>
          <input id="n3" type="number" />
          <label>Qty 3</label>
          <input id="q3" type="number" />
          <Text id="t3"></Text>
          <Text color="red" id="w3"></Text>
        </HStack>{" "}
        <label>Sold at</label>
        <input id="tott" type="number" />
        Total MRP
        <Text id="total_mrp" />
        Total Discount
        <Text id="diis" />
        <button
          onClick={() => {
            var t1 =
              document.getElementById("n1").value *
              document.getElementById("q1").value;
            var t2 =
              document.getElementById("n2").value *
              document.getElementById("q2").value;
            var t3 =
              document.getElementById("n3").value *
              document.getElementById("q3").value;
            document.getElementById("t1").innerHTML = t1;
            document.getElementById("t2").innerHTML = t2;
            document.getElementById("t3").innerHTML = t3;

            var t = t1 + t2 + t3;
            var dis = t - document.getElementById("tott").value;

            var v1 = (dis / t) * t1;
            var v2 = (dis / t) * t2;
            var v3 = (dis / t) * t3;
            document.getElementById("total_mrp").innerHTML = t;
            document.getElementById("diis").innerHTML = dis;

            document.getElementById("w1").innerHTML = v1;
            document.getElementById("w2").innerHTML = v2;
            document.getElementById("w3").innerHTML = v3;
          }}
        >
          {" "}
          Submit
        </button>
      </SimpleGrid>
    </Box>
  );
}
export default App;
