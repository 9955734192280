import {
  ChakraProvider,
  Heading,
  Center,
  Flex,
  Spacer,
  Button,
} from "@chakra-ui/react";
import API from "./secrets";
import { FiDownloadCloud } from "react-icons/fi";
import { MdAdd, MdInventory2, MdOutlineInventory } from "react-icons/md";
import List from "./List";
import AddForm from "./AddForm";
import TransactionList from "./TransactionList";
import ProductDetail from "./ProductDetail";
import Calculator from "./Calculator";

import { Box } from "@chakra-ui/react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App({ Component }) {
  return (
    <ChakraProvider>
      <Box minH="100vh" h="100%" bgColor="#194350" p="1rem" className="App">
        <header className="App-header">
          <Center>
            <Heading color="#FF8882" p="1rem">
              <a href="/"> RK Traders </a>
            </Heading>
          </Center>
        </header>

        <Flex>
          <a href="/">
            <Button leftIcon={<MdInventory2 />} w="30vw" colorScheme="purple">
              Stock
            </Button>
          </a>
          <Spacer />
          <a href="t">
            <Button
              leftIcon={<MdOutlineInventory />}
              w="30vw"
              colorScheme="cyan"
            >
              Transactions
            </Button>
          </a>
          <Spacer />
          <a href="add">
            <Button w="30vw" leftIcon={<MdAdd />} colorScheme="pink">
              Add
            </Button>
          </a>
          {/* <a href={API + "/generateXls"}>
            <IconButton ml="1rem">
              <FiDownloadCloud />
            </IconButton>
          </a> */}
        </Flex>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<List />} />
            <Route path="/t" element={<TransactionList />} />
            <Route path="/add" element={<AddForm />} />
            <Route path="/productDetail" element={<ProductDetail />} />
            <Route path="/calc" element={<Calculator />} />
          </Routes>
        </BrowserRouter>
      </Box>
    </ChakraProvider>
  );
}

export default App;
