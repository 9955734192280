import { Box, Spinner, Heading, Center } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import TransactionItem from "./TransactionItem";
import CategoryButton from "./CatButton";
import API from "./secrets";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function App() {
  const [listData, setListData] = useState({});
  const [listDataReady, setListDataReady] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [action, setAction] = useState("");
  // const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    !listDataReady
      ? fetch(API + "/transactions/?pid=" + queryParams.get("pid"))
          .then((res) => res.json())
          .then((res) => {
            setListData(res);
            setListDataReady(true);
          })
      : console.log("ready");
  }, [listDataReady, searchText, searchCategory, startDate, action]);

  return (
    <Box>
      <Heading textColor="white" size="lg" p="1rem">
        History
      </Heading>
      {listDataReady ? (
        listData.map((e) => {
          return (
            <TransactionItem
              key={e.createdAt}
              data={e}
              showDate={true}
              setListDataReady={setListDataReady}
            />
          );
        })
      ) : (
        <Center m="3rem">
          <Spinner size="xl" color="white" />
        </Center>
      )}
    </Box>
  );
}
export default App;
