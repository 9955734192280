import {
  Box,
  Input,
  Button,
  Spinner,
  Grid,
  GridItem,
  Switch,
  Text,
  HStack,
  Center,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Item from "./Item";
import CategoryButton from "./CatButton";
import API from "./secrets";
function App() {
  const [listData, setListData] = useState({});
  const [listDataReady, setListDataReady] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [itemCounter, setItemCounter] = useState(0);
  const [isDefaultExpanded, setIsDefaultExpanded] = useState(false);
  useEffect(() => {
    !listDataReady
      ? fetch(API + "/?s=" + searchText + "&category=" + searchCategory)
          .then((res) => res.json())
          .then((res) => {
            setListData(res);
            setListDataReady(true);
            setItemCounter(res.length);
          })
      : console.log("ready");
    fetch(API + "/getCategory")
      .then((res) => res.json())
      .then((res) => {
        setCategoryList(res);
      });
  }, [listDataReady, searchText, searchCategory]);
  return (
    <Box>
      <Box p="1rem">
        <HStack>
          <Text textColor="white" fontSize="md">
            Search
          </Text>

          <Input
            m="1rem"
            bgColor="white"
            onChange={(e) => {
              console.log(e.target.value);
              setSearchText(e.target.value);
              setListDataReady(false);
            }}
          />
          <Text textColor="white"> {itemCounter}</Text>
        </HStack>
      </Box>
      <CategoryButton
        categoryList={categoryList}
        setSearchCategory={setSearchCategory}
        setListDataReady={setListDataReady}
        defaultValue={searchCategory}
      />
      <HStack>
        {/* <Text textColor="white"> Show Details</Text> */}
        <Switch
          onChange={() => {
            setIsDefaultExpanded((v) => !v);
            console.log(isDefaultExpanded);
            setListDataReady(false);
          }}
        />
      </HStack>
      {searchText !== "" || searchCategory !== "" ? (
        listDataReady ? (
          listData.map((e) => {
            return (
              <Item
                isDefaultExpanded={isDefaultExpanded}
                key={e._id}
                data={e}
                setListDataReady={setListDataReady}
              />
            );
          })
        ) : (
          <Center p="3rem">
            {" "}
            <Spinner size="xl" color="white" />
          </Center>
        )
      ) : (
        <Box mt="2rem" bgColor="white" borderRadius="10" h="">
          <Grid
            p={["0.5rem", null, "1rem"]}
            templateColumns={[
              "repeat(2, 1fr)",
              "repeat(2, 1fr)",
              "repeat(3, 1fr)",
              "repeat(4, 1fr)",
              "repeat(5, 1fr)",
            ]}
            h="auto"
            w="100%"
          >
            {categoryList.map((e) => {
              return (
                <GridItem m={["0.5rem", null, "1rem"]}>
                  <Button
                    w="100%"
                    h="90"
                    colorScheme="teal"
                    shadow="dark-lg"
                    onClick={() => {
                      setSearchCategory(e);
                      setListDataReady(false);
                    }}
                  >
                    {e.toUpperCase()}
                  </Button>
                </GridItem>
              );
            })}
          </Grid>
        </Box>
      )}
    </Box>
  );
}
export default App;
