import { Box, Text, Select } from "@chakra-ui/react";

function App({
  categoryList,
  setSearchCategory,
  setListDataReady,
  defaultValue,
}) {
  return (
    <Box p="1rem">
      <Text textColor="white">Categories</Text>
      <Select
        bgColor="white"
        value={defaultValue}
        onChange={(event) => {
          setSearchCategory(event.target.value);
          setListDataReady(false);
        }}
      >
        <option key="all" value="">
          ALL
        </option>
        {categoryList.map((e) => {
          return (
            <>
              <option key={e} value={e}>
                {e.toUpperCase()}
              </option>
            </>
          );
        })}
      </Select>
    </Box>
  );
}
export default App;
