import {
  Box,
  Spinner,
  Radio,
  Stack,
  RadioGroup,
  Center,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import TransactionItem from "./TransactionItem";
import CategoryButton from "./CatButton";
import API from "./secrets";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function App() {
  const [listData, setListData] = useState({});
  const [listDataReady, setListDataReady] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [action, setAction] = useState("");

  useEffect(() => {
    !listDataReady
      ? fetch(API + "/transactions/?date=" + startDate + "&action=" + action)
          .then((res) => res.json())
          .then((res) => {
            setListData(res);
            setListDataReady(true);
          })
      : console.log("ready");
  }, [listDataReady, searchText, searchCategory, startDate, action]);

  return (
    <Box>
      <Box m="1rem">
        <Stack>
          <DatePicker
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
              setListDataReady(false);
            }}
            dateFormat="dd MMM yyyy"
          />
          <RadioGroup defaultValue="all" color="white">
            <Stack spacing={5} direction="row">
              <Radio
                colorScheme="black"
                value="all"
                onChange={(e) => {
                  setAction("");
                  setListDataReady(false);
                }}
              >
                All
              </Radio>
              <Radio
                colorScheme="red"
                value="sell"
                onChange={(e) => {
                  setAction(e.target.value);
                  setListDataReady(false);
                }}
              >
                SELL
              </Radio>{" "}
              <Radio
                colorScheme="blue"
                value="modify"
                onChange={(e) => {
                  setAction(e.target.value);
                  setListDataReady(false);
                }}
              >
                MODIFY
              </Radio>{" "}
              <Radio
                colorScheme="green"
                value="add"
                onChange={(e) => {
                  setAction(e.target.value);
                  setListDataReady(false);
                }}
              >
                ADD
              </Radio>
            </Stack>
          </RadioGroup>
        </Stack>
      </Box>
      {listDataReady ? (
        listData.map((e) => {
          return (
            <TransactionItem
              key={e.createdAt}
              data={e}
              setListDataReady={setListDataReady}
            />
          );
        })
      ) : (
        <Center m="3rem">
          <Spinner size="xl" color="white" />
        </Center>
      )}
    </Box>
  );
}
export default App;
