import {
  Box,
  Input,
  Button,
  Flex,
  Spinner,
  Image,
  FormControl,
  FormLabel,
  HStack,
  Switch,
  Select,
} from "@chakra-ui/react";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";

import { React, useState, useRef, useEffect } from "react";
import API from "./secrets";
function App() {
  const [oldCat, setOldCat] = useState(true);
  const [uploadImage, setUploadImage] = useState("no-upload");
  const [imageUri, setImageUri] = useState();
  const [submittingForm, setSubmittingForm] = useState(false);
  const [catOptions, setCatOptions] = useState({});
  const [categoryList, setCategoryList] = useState([]);
  useEffect(() => {
    fetch(API + "/getCategory")
      .then((res) => res.json())
      .then((res) => {
        setCategoryList(res);
      });
  }, []);
  return (
    <Box bgColor="whatsapp.100" borderRadius="10" p="1rem" m="1rem">
      <form
        method="post"
        action={API + "/add"}
        onSubmit={() => setSubmittingForm(true)}
      >
        <FormControl as="action">
          <FormLabel as="action">Do what?</FormLabel>
        </FormControl>
        <Switch
          colorScheme="red"
          onChange={() => {
            setOldCat(!oldCat);
            setCatOptions({});
          }}
          name="__action"
        >
          Create Category
        </Switch>
        {oldCat ? (
          <Select
            required
            onChange={(e) => {
              fetch(API + "/getCategoryParam/?category=" + e.target.value)
                .then((res) => res.json())
                .then((res) => {
                  setCatOptions(res);
                });
            }}
            placeholder="Select category"
            bgColor="white"
            name="category"
          >
            {categoryList.map((e) => {
              return (
                <option key={e} name="category" value={e}>
                  {e}
                </option>
              );
            })}
          </Select>
        ) : (
          <FormControl isRequired>
            <FormLabel htmlFor="category">Category</FormLabel>
            <Input bgColor="white" name="category" />
          </FormControl>
        )}

        <Flex>
          {/* <FormControl>
            <FormLabel htmlFor="sl_no">Sl. No.</FormLabel>
            <Input bgColor="white" name="sl_no" />
          </FormControl> */}
          <FormControl mr="1rem">
            <FormLabel htmlFor="id_no">ID No</FormLabel>
            <Input bgColor="white" name="id_no" />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="cuurent_stock">Initial stock</FormLabel>
            <Input
              type="number"
              defaultValue={1}
              bgColor="blue.200"
              name="current_stock"
            />
          </FormControl>
        </Flex>
        <FormControl isRequired>
          <FormLabel htmlFor="pirticular">Pirticular</FormLabel>
          <Input bgColor="white" name="pirticular" />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="supplier">Supplier</FormLabel>
          <Input bgColor="white" name="supplier" />
        </FormControl>

        <Flex p="1rem" m="1rem" bgColor="red.100" borderRadius="10">
          <FormControl isRequired mr="1rem">
            <FormLabel htmlFor="dp">DP</FormLabel>
            <Input type="number" required bgColor="white" name="dp" />
          </FormControl>

          <FormControl mr="1rem">
            <FormLabel htmlFor="dp_extra">Extras</FormLabel>
            <Input
              type="number"
              defaultValue="0"
              bgColor="white"
              name="dp_extra"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="mrp">MRP</FormLabel>
            <Input type="number" required bgColor="white" name="mrp" />
          </FormControl>
        </Flex>

        {!oldCat ? (
          <Box>
            <FormControl>
              <HStack>
                <FormLabel>Extra 1</FormLabel>

                <Input
                  bgColor="white"
                  name="r1_key"
                  placeholder="Property Name"
                />
                <Input
                  bgColor="white"
                  name="r1_value"
                  placeholder="This will be displayed along with name"
                />
              </HStack>
            </FormControl>
            <FormControl>
              <HStack>
                <FormLabel>Extra 2</FormLabel>

                <Input
                  bgColor="white"
                  name="r2_key"
                  placeholder="Property Name"
                />
                <Input
                  bgColor="white"
                  name="r2_value"
                  placeholder="This will be displayed along with name"
                />
              </HStack>
            </FormControl>
            <FormControl>
              <HStack>
                <FormLabel>Extra 3</FormLabel>

                <Input
                  bgColor="white"
                  name="r3_key"
                  placeholder="Property Name"
                />
                <Input bgColor="white" name="r3_value" />
              </HStack>
            </FormControl>
            <FormControl>
              <HStack>
                <FormLabel>Extra 4</FormLabel>

                <Input
                  bgColor="white"
                  name="r4_key"
                  placeholder="Property Name"
                />
                <Input bgColor="white" name="r4_value" placeholder="" />
              </HStack>
            </FormControl>
            <FormControl>
              <HStack>
                <FormLabel>Extra 5</FormLabel>

                <Input
                  bgColor="white"
                  name="r5_key"
                  placeholder="Property Name"
                />
                <Input bgColor="white" name="r5_value" placeholder="" />
              </HStack>
            </FormControl>
          </Box>
        ) : (
          ""
        )}
        {catOptions.name ? (
          <Box>
            {catOptions.r1_key ? (
              <FormControl>
                <HStack>
                  <Input
                    name="r1_key"
                    value={catOptions.r1_key}
                    readonly={true}
                  />

                  <Input bgColor="white" name="r1_value" placeholder="value" />
                </HStack>
              </FormControl>
            ) : (
              ""
            )}
            {catOptions.r2_key ? (
              <FormControl>
                <HStack>
                  <Input
                    name="r2_key"
                    value={catOptions.r2_key}
                    readonly={true}
                  />

                  <Input bgColor="white" name="r2_value" placeholder="value" />
                </HStack>
              </FormControl>
            ) : (
              ""
            )}{" "}
            {catOptions.r3_key ? (
              <FormControl>
                <HStack>
                  <Input
                    name="r3_key"
                    value={catOptions.r3_key}
                    readonly={true}
                  />

                  <Input bgColor="white" name="r3_value" placeholder="value" />
                </HStack>
              </FormControl>
            ) : (
              ""
            )}{" "}
            {catOptions.r4_key ? (
              <FormControl>
                <HStack>
                  <Input
                    name="r4_key"
                    value={catOptions.r4_key}
                    readonly={true}
                  />
                  <Input bgColor="white" name="r4_value" placeholder="value" />
                </HStack>
              </FormControl>
            ) : (
              ""
            )}{" "}
            {catOptions.r5_key ? (
              <FormControl>
                <HStack>
                  <Input
                    name="r5_key"
                    value={catOptions.r5_key}
                    readonly={true}
                  />
                  <Input bgColor="white" name="r5_value" placeholder="value" />
                </HStack>
              </FormControl>
            ) : (
              ""
            )}
          </Box>
        ) : (
          ""
        )}
        <Switch
          colorScheme="blue"
          onChange={() => {
            setUploadImage((r) => (r === "no-upload" ? "upload" : "no-upload"));
          }}
          name="camera"
        >
          Upload Image
        </Switch>
        {uploadImage === "upload" ? (
          <Camera
            idealFacingMode={FACING_MODES.ENVIRONMENT}
            idealResolution={{ width: 500, height: 300 }}
            imageType={IMAGE_TYPES.PNG}
            imageCompression={0.2}
            isImageMirror={true}
            onTakePhoto={(dataUri) => {
              setUploadImage("show");
              setImageUri(dataUri);
              console.log(dataUri);
            }}
          />
        ) : uploadImage === "show" ? (
          <Flex>
            {imageUri ? (
              <>
                <Button
                  colorScheme="yellow"
                  onClick={() => {
                    setImageUri(undefined);
                    setUploadImage("upload");
                  }}
                >
                  Delete
                </Button>{" "}
                <Image alt="phot" src={imageUri} />{" "}
              </>
            ) : (
              ""
            )}
          </Flex>
        ) : (
          ""
        )}
        <input name="image" value={imageUri} hidden />
        <Flex bgColor="pink.100" m="1rem" borderRadius="1rem" p="1rem">
          <FormControl mr="1rem">
            <FormLabel htmlFor="comment">Additional Comment</FormLabel>
            <Input bgColor="white" name="comment" />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="author">Author</FormLabel>
            <Input bgColor="white" name="author" />
          </FormControl>
        </Flex>
        <Button
          disabled={submittingForm}
          type="submit"
          colorScheme="cyan"
          m="1rem"
        >
          Add
        </Button>
        {submittingForm ? <Spinner /> : ""}
      </form>
    </Box>
  );
}
export default App;
