import {
  Box,
  Input,
  Button,
  Flex,
  Image,
  FormControl,
  Switch,
  FormLabel,
} from "@chakra-ui/react";
import InputSpinner from "react-bootstrap-input-spinner";
import { useState } from "react";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
function App({ setCardData, cardData }) {
  const [uploadImage, setUploadImage] = useState("no-upload");
  const [imageUri, setImageUri] = useState();

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setCardData((values) => ({ ...values, [name]: value }));
  };

  return (
    <Box>
      <FormControl>
        <FormLabel htmlFor="category">Category</FormLabel>
        <Input
          bgColor="gray"
          name="category"
          readOnly="readonly"
          value={cardData.category || ""}
          onChange={handleChange}
        />
      </FormControl>
      <Flex>
        <FormControl mr="1rem">
          <FormLabel htmlFor="id_no">ID No.</FormLabel>
          <Input
            bgColor="white"
            name="id_no"
            value={cardData.id_no || ""}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel htmlFor="cuurent_stock">Current stock</FormLabel>
          <Input
            bgColor="blue.300"
            name="current_stock"
            value={cardData.current_stock || ""}
            onChange={handleChange}
          />
        </FormControl>
      </Flex>
      <FormControl isRequired>
        <FormLabel htmlFor="pirticular">Pirticular</FormLabel>
        <Input
          bgColor="white"
          name="pirticular"
          value={cardData.pirticular || ""}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="supplier">Supplier</FormLabel>
        <Input
          bgColor="white"
          name="supplier"
          value={cardData.supplier || ""}
          onChange={handleChange}
        />
      </FormControl>

      {cardData.r1_key ? (
        <FormControl>
          <FormLabel htmlFor={cardData.r1_key}>{cardData.r1_key}</FormLabel>
          <Input
            bgColor="white"
            name="r1_value"
            value={cardData.r1_value || ""}
            onChange={handleChange}
          />
        </FormControl>
      ) : (
        ""
      )}
      {cardData.r2_key ? (
        <FormControl>
          <FormLabel htmlFor={cardData.r2_key}>{cardData.r2_key}</FormLabel>
          <Input
            bgColor="white"
            name="r2_value"
            value={cardData.r2_value || ""}
            onChange={handleChange}
          />
        </FormControl>
      ) : (
        ""
      )}
      {cardData.r3_key ? (
        <FormControl>
          <FormLabel htmlFor={cardData.r3_key}>{cardData.r3_key}</FormLabel>
          <Input
            bgColor="white"
            name="r3_value"
            value={cardData.r3_value || ""}
            onChange={handleChange}
          />
        </FormControl>
      ) : (
        ""
      )}
      {cardData.r4_key ? (
        <FormControl>
          <FormLabel htmlFor={cardData.r4_key}>{cardData.r4_key}</FormLabel>
          <Input
            bgColor="white"
            name="r4_value"
            value={cardData.r4_value || ""}
            onChange={handleChange}
          />
        </FormControl>
      ) : (
        ""
      )}
      {cardData.r5_key ? (
        <FormControl>
          <FormLabel htmlFor={cardData.r5_key}>{cardData.r5_key}</FormLabel>
          <Input
            bgColor="white"
            name="r5_value"
            value={cardData.r5_value || ""}
            onChange={handleChange}
          />
        </FormControl>
      ) : (
        ""
      )}
      <Flex bgColor="whatsapp.100" p="1rem" m="0.5rem" borderRadius="10">
        <FormControl isRequired mr="1rem">
          <FormLabel htmlFor="dp">DP</FormLabel>
          <Input
            type="number"
            bgColor="white"
            name="dp"
            value={parseInt(cardData.dp) || ""}
            onChange={handleChange}
          />
        </FormControl>

        <FormControl mr="1rem">
          <FormLabel htmlFor="dp_extra">DP Extras</FormLabel>
          <Input
            bgColor="white"
            name="dp_extra"
            value={cardData.dp_extra || ""}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl isRequired mr="1rem">
          <FormLabel htmlFor="mrp">MRP</FormLabel>
          <Input
            type="number"
            bgColor="white"
            name="mrp"
            value={parseInt(cardData.mrp) || ""}
            onChange={handleChange}
          />
        </FormControl>
      </Flex>
      <Flex>
        <FormControl mr="1rem">
          <FormLabel htmlFor="comment">Reason for modification</FormLabel>
          <Input
            bgColor="white"
            name="comment"
            value={cardData.comment || ""}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="author">Author</FormLabel>
          <Input
            bgColor="white"
            name="author"
            value={cardData.author || ""}
            onChange={handleChange}
          />
        </FormControl>
      </Flex>
      {cardData.imageObj ? (
        <Switch
          colorScheme="blue"
          onChange={() => {
            setCardData((values) => ({
              ...values,
              deleteImage: !values.deleteImage || false,
            }));
          }}
          name="delete"
        >
          Remove Image{" "}
        </Switch>
      ) : (
        <>
          <Switch
            colorScheme="blue"
            onChange={() => {
              setUploadImage((r) =>
                r === "no-upload" ? "upload" : "no-upload"
              );
            }}
            name="camera"
          >
            Set Image
          </Switch>
          {uploadImage === "upload" ? (
            <Camera
              idealFacingMode={FACING_MODES.ENVIRONMENT}
              idealResolution={{ width: 500, height: 300 }}
              imageType={IMAGE_TYPES.PNG}
              imageCompression={0.1}
              onTakePhoto={(dataUri) => {
                setUploadImage("show");
                setImageUri(dataUri);
                setCardData((values) => ({ ...values, image: dataUri }));
                console.log(dataUri);
              }}
            />
          ) : uploadImage === "show" ? (
            <Flex>
              {imageUri ? (
                <>
                  <Button
                    colorScheme="yellow"
                    onClick={() => {
                      setImageUri(undefined);
                      setUploadImage("upload");
                    }}
                  >
                    Delete
                  </Button>{" "}
                  <Image alt="phot" src={imageUri} />{" "}
                </>
              ) : (
                ""
              )}
            </Flex>
          ) : (
            ""
          )}
        </>
      )}
    </Box>
  );
}
export default App;
