import {
  Box,
  Flex,
  Spacer,
  useToast,
  Text,
  Grid,
  HStack,
  IconButton,
  Stack,
  GridItem,
} from "@chakra-ui/react";

import { GrRevert } from "react-icons/gr";

import axios from "axios";
import { React, useState, useRef, useEffect } from "react";
import ReactJson from "react-json-view";
import API from "./secrets";
function App({ data, setListDataReady, showDate }) {
  var {
    pid,
    action,
    comment,
    old_snapshot,
    new_snapshot,
    author,
    createdAt,
    _id,
    isReversed,
    sold_at_price,
    modification_string,
  } = data;
  const [isExpanded, setIsExpanded] = useState(false);
  const toast = useToast();
  useEffect(() => {
    if (new_snapshot) {
      delete new_snapshot.__v;
      delete new_snapshot._id;
    }
    if (old_snapshot) {
      delete old_snapshot.__v;
      delete old_snapshot._id;
    }
  });
  return (
    <Box
      p="1rem"
      m="0.2rem"
      borderRadius={5}
      shadow="lg"
      bgColor={
        isReversed
          ? "gray"
          : action === "sell"
          ? "red.100"
          : action === "add"
          ? "green.100"
          : "blue.200"
      }
      onClick={() => {
        setIsExpanded(!isExpanded);
      }}
    >
      {" "}
      <Flex>
        {isReversed ? (
          <strike>{action.toUpperCase()}</strike>
        ) : (
          <strong>
            {action.toUpperCase()}
            {" - "} {new_snapshot.category}
          </strong>
        )}
        <Spacer />
        Time:{" "}
        <strong>
          {!showDate
            ? new Date(createdAt).toLocaleTimeString("en-US", {
                hour: "numeric",
                minute: "numeric",
                day: "2-digit",
                hour12: true,
              })
            : new Date(createdAt).toLocaleString()}
        </strong>
      </Flex>
      {isReversed ? (
        <Text color="red" fontSize="3xl">
          Reversed
        </Text>
      ) : (
        ""
      )}
      <HStack>
        <Text>#{new_snapshot.id}</Text>
        <Text textColor="Brown">{new_snapshot.pirticular}</Text>
        <Text textColor="blue">{new_snapshot.r1_value}</Text>
        <Text textColor="brown">{new_snapshot.r2_value}</Text>
      </HStack>
      <Grid
        templateColumns={["repeat(2, 1fr)", "repeat(4, 1fr)", "repeat(6, 1fr)"]}
        h="auto"
      >
        {action === "sell" ? (
          <>
            <GridItem>
              <HStack>
                <Text>Sold Qty:</Text>
                <Text fontSize="2xl">
                  <strong>
                    {parseInt(old_snapshot.current_stock) -
                      parseInt(new_snapshot.current_stock)}
                  </strong>
                </Text>
              </HStack>
            </GridItem>
            <GridItem>
              <HStack>
                <Text>SP:</Text>
                <Text fontSize="2xl">
                  <strong>{sold_at_price}</strong>
                </Text>
              </HStack>
            </GridItem>
          </>
        ) : action === "add" ? (
          <>
            <GridItem>
              <HStack>
                <Text>Added Qty:</Text>
                <Text fontSize="2xl">
                  <strong>
                    {old_snapshot
                      ? parseInt(new_snapshot.current_stock) -
                        parseInt(old_snapshot.current_stock)
                      : new_snapshot.current_stock}
                  </strong>
                </Text>
              </HStack>
            </GridItem>
          </>
        ) : (
          ""
        )}
        {/* <GridItem>
          Author:<strong>{author}</strong>
        </GridItem> */}{" "}
        {comment ? (
          <GridItem>
            Comment:<strong>{comment}</strong>
          </GridItem>
        ) : (
          ""
        )}
      </Grid>
      <Flex>
        {action === "modify" ? (
          <Stack bgColor="white" borderRadius="10" w="100%" p="0.5rem">
            {modification_string.map((e) => {
              return (
                <HStack>
                  <Text>
                    <strong>{e.key}:</strong>
                  </Text>
                  <Text>
                    <strike>{e.old}</strike>
                  </Text>
                  <Text>{e.new}</Text>
                </HStack>
              );
            })}
          </Stack>
        ) : (
          ""
        )}
      </Flex>
      <ReactJson
        src={old_snapshot}
        displayDataTypes={false}
        enableClipboard={false}
        displayObjectSize={false}
        collapsed={true}
        name="OLD"
      />
      <Spacer />
      <ReactJson
        src={new_snapshot}
        displayDataTypes={false}
        enableClipboard={false}
        displayObjectSize={false}
        collapsed={true}
        name="NEW"
      />
      {!isReversed ? (
        <IconButton
          icon={<GrRevert />}
          colorScheme="red"
          variant="outline"
          onClick={() => {
            axios
              .post(API + "/reverseTransaction", {
                trans_id: _id,
              })
              .then((res) => {
                console.log(res.data);
                if (res.data.code === 200) setListDataReady(false);
                res.data.code === 200
                  ? toast({
                      title: "Reversed",
                      status: "success",
                      duration: 5000,
                      isClosable: true,
                    })
                  : toast({
                      title: "Reverse newer transaction on this product first",
                      status: "error",
                      duration: 5000,
                      isClosable: true,
                    });
              })
              .catch((e) => {
                console.log(e);
              });
          }}
        ></IconButton>
      ) : (
        ""
      )}
    </Box>
  );
}
export default App;
