import {
  Box,
  Input,
  Button,
  Flex,
  HStack,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { useEffect, useState } from "react";
import InputSpinner from "react-bootstrap-input-spinner";

function App({ setCardData, cardData }) {
  const [startDate, setStartDate] = useState(new Date());

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setCardData((values) => ({ ...values, [name]: value }));
  };
  const [oldStock, setOldStock] = useState(cardData.current_stock);

  const old_qty = Object.assign({}, cardData);
  useEffect(() => {
    setCardData((values) => ({ ...values, createdAt: new Date() }));
  }, [setCardData]);
  return (
    <Box>
      <FormControl>
        <FormLabel htmlFor="sl_no">Available Qty</FormLabel>
        <Input
          bgColor="grey"
          name="av_qty"
          value={oldStock || ""}
          readOnly="readOnly"
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="sold_qty">Sold Quantity</FormLabel>
        <Flex>
          <Button
            colorScheme="red"
            onClick={() => {
              document.getElementById("sold_stock_qty").value =
                parseInt(document.getElementById("sold_stock_qty").value) - 1;
              var val =
                parseInt(document.getElementById("sold_stock_qty").value) || 0;
              var old = parseInt(oldStock);
              console.log(val, old);
              var new_qt = old - val;
              setCardData((values) => ({ ...values, current_stock: new_qt }));
            }}
          >
            -
          </Button>
          <Input
            ml="1rem"
            mr="1rem"
            bgColor="white"
            name="sold_stock"
            id="sold_stock_qty"
            type="number"
            defaultValue="0"
            onChange={(e) => {
              var val = parseInt(e.target.value) || 0;
              var old = parseInt(oldStock);
              console.log(val, old);
              var new_qt = old - val;
              setCardData((values) => ({ ...values, current_stock: new_qt }));
            }}
          />{" "}
          <Button
            colorScheme="green"
            onClick={() => {
              document.getElementById("sold_stock_qty").value =
                parseInt(document.getElementById("sold_stock_qty").value) + 1;
              var val =
                parseInt(document.getElementById("sold_stock_qty").value) || 0;
              var old = parseInt(oldStock);
              console.log(val, old);
              var new_qt = old - val;
              setCardData((values) => ({ ...values, current_stock: new_qt }));
            }}
          >
            +
          </Button>
        </Flex>
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="current_stock">Updated Qty</FormLabel>
        <Input
          bgColor="gray"
          name="current_stock"
          value={cardData.current_stock || 0}
          onChange={handleChange}
          readOnly="readOnly"
        />
      </FormControl>

      <FormControl>
        <FormLabel htmlFor="sell date">Sell Date</FormLabel>
        <DatePicker
          selected={cardData.createdAt}
          name="createdAt"
          onChange={(date) => {
            setCardData((values) => ({
              ...values,
              comment: "late entry: " + new Date().toLocaleString(),
            }));
            setCardData((values) => ({ ...values, createdAt: date }));
          }}
          dateFormat="dd MMM yyyy"
        />
      </FormControl>

      <FormControl>
        <HStack>
          <FormLabel htmlFor="sold_at_price">Selling Price</FormLabel>
          <Input
            bgColor="green.100"
            type="number"
            // defaultValue={parseInt(cardData.mrp)}
            name="sold_at_price"
            value={cardData.sold_at_price || ""}
            onChange={handleChange}
          />
        </HStack>
      </FormControl>

      <FormControl>
        <HStack>
          <FormLabel htmlFor="comment">Additional Comments</FormLabel>
          <Input
            bgColor="white"
            name="comment"
            value={cardData.comment || ""}
            onChange={handleChange}
          />
        </HStack>
      </FormControl>
    </Box>
  );
}
export default App;
